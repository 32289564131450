const btnsChannel = document.querySelectorAll("[data-trigger='close-channel']");

btnsChannel.forEach(function(btnClose) {
  btnClose.addEventListener("click", function(e) {
    if (e.isTrusted) {
      var date = new Date();
      date.setTime(date.getTime()+(0.5*24*60*60*1000));
      document.cookie = "cookies_channel=true; expires="+date.toGMTString();
    }
  });
});

